export const fieldErrorMessage = (errors, field) => {
    return errors?.find((error) => error.field === field);
};
export const fieldsErrorMessages = (errors, fields) => {
    return fields
        .map((field) => fieldErrorMessage(errors, field))
        .filter((field) => field)
        .join(', ');
};
export const baseErrorMessages = (errors) => {
    if (!errors) {
        return [];
    }
    return errors.filter((error) => error.field === 'base');
};
export const parseBaseErrorsFromResponse = (errs) => {
    let errors = errs.map((graphErr) => ({
        field: 'base',
        message: graphErr.message,
        code: graphErr.extensions.code,
    }));
    errors = errors.filter((err) => err.code === 'USER_ERROR');
    return { errors };
};
export const parseZodErrors = (zodError, messageFunction) => {
    return zodError.errors.map((err) => ({
        field: err.path
            .map((path, index) => (index === 0 ? path : `[${path}]`))
            .join(''),
        code: err.code,
        message: messageFunction(`${err.path.at(-1)}.${err.code}`),
    }));
};
export const logErrors = (errors, sentry) => {
    console.error(errors);
    try {
        sentry.captureException(errors);
    }
    catch (e) {
        console.error(e);
        // do nothing
    }
};
export const logBoundaryErrors = (errors, sentry) => {
    console.error(errors);
    try {
        sentry.captureRemixErrorBoundaryError(errors);
    }
    catch (e) {
        console.error(e);
        // do nothing
    }
};
